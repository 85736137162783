import React from 'react';

import { Empty } from 'antd';
import styled from 'styled-components';

import { AdsProviderLogo } from './AdsProviderLogo';
import { AdsProviderLogoutButton } from './AdsProviderLogoutButton';
import { AdsProviderReloginButton } from './AdsProviderReLoginButton';
import { FacebookSettings } from './FacebookSettings';
import { NewAdsProviderButton } from './NewAdsProviderButton';
import type { accountT } from '../../../../../flow-typed/pixelme/types';
import { adsAlreadyProcessed, adsNeverProcessed } from '../../../../lib/adsProvider/common';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { BoxShadow } from '../../../BoxShadow';
import { ADS_PROVIDERS, useGetShouldReconnect } from '../constants';
import { useQueryParams } from 'hooks/useQueryParams';
import { WIDGET_API_CONNECTION } from 'consts/consts';

const Style = styled.div`
  width: 100%;
  padding: 1em;
  margin-left: -1em;
`;

const ConnectionText = styled.div`
  color: var(--text-muted);
`;

const RightAligned = styled.div`
  margin-left: auto;
`;

function ConnectedIndicator(props: { adsProvider: adsProviderT }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#2EC1B3" />
      </svg>
      <ConnectionText>
        {adsNeverProcessed(props.adsProvider) && (
          <span>
            <div>Waiting for processing</div>
            <div>Data will be automatically updated by Google every day</div>
          </span>
        )}
        {adsAlreadyProcessed(props.adsProvider) && (
          <span>
            <div>Connected</div>
            <div>Data is automatically updated every day</div>
          </span>
        )}
      </ConnectionText>
    </div>
  );
}

function NotConnectedIndicator() {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#FF0000" />
      </svg>
      <ConnectionText>Not connected</ConnectionText>
    </div>
  );
}

type AdsProviderAccountsProps = {
  account: accountT,
  adsProviders?: adsProviderT[],
  hideDisconnect?: boolean,
  hideAddNew?: boolean,
};
export const AdsProviderAccounts = ({
  account,
  adsProviders,
  hideDisconnect,
  hideAddNew,
}: AdsProviderAccountsProps) => {
  const shouldReconnect = useGetShouldReconnect();
  const filteredAdsProviders = (adsProviders || getAdsProviders(account)).filter((p) => !p.generated);
  const query = useQueryParams();
  const widgetStartType = query.get(WIDGET_API_CONNECTION);

  return (
    <Style>
      {filteredAdsProviders
        .sort((p1, p2) => (shouldReconnect(p2) ? 1 : 0) - (shouldReconnect(p1) ? 1 : 0))
        .map((provider: adsProviderT) => (
          <BoxShadow key={provider.id} style={{ width: '100%', display: 'block' }} warning={shouldReconnect(provider)}>
            <div>
              <b>Ad account: </b>
              {`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2em',
                maxWidth: '850px',
                marginTop: '0.5rem',
              }}
            >
              <AdsProviderLogo providerType={provider.providerType} style={{ width: '20px', height: '20px' }} />
              {provider.providerType}
              {shouldReconnect(provider) ? (
                <>
                  {provider.loginNeeded && <NotConnectedIndicator />}
                  <RightAligned>
                    <AdsProviderReloginButton account={account} provider={provider} />
                  </RightAligned>
                </>
              ) : (
                <>
                  <ConnectedIndicator adsProvider={provider} />
                  <RightAligned>
                    {provider.providerType === ADS_PROVIDERS.FACEBOOK && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                        <AdsProviderReloginButton account={account} provider={provider} />
                        <FacebookSettings account={account} provider={provider} />
                      </div>
                    )}
                    {provider.providerType === ADS_PROVIDERS.GOOGLE && (
                      <>
                        <div>Currency: {provider.externalAccountCurrencyCode || 'USD'}</div>
                      </>
                    )}
                  </RightAligned>
                </>
              )}
            </div>
            {!hideDisconnect && <AdsProviderLogoutButton account={account} provider={provider} />}
          </BoxShadow>
        ))}
      {filteredAdsProviders.length === 0 && (
        <Empty style={{ backgroundColor: 'white', marginLeft: '1em', marginRight: '-1em', paddingBottom: '2em' }} />
      )}
      <br />
      {!hideAddNew && (
        <div style={{ marginLeft: '1em' }}>
          <NewAdsProviderButton account={account} widgetStartType={widgetStartType} />
        </div>
      )}
    </Style>
  );
};
